<template>
  <div v-if="landing.advanced.global.active_slider" style="overflow:hidden;">
    <div v-for="(slider,grInd) in slides" :key="slider.id">
      <div  style="padding:10px;" :style="`background-color:${slider.background};`" v-if="slider.show_type === position">
        <div v-if="slider.title"  style="border-radius:0;margin:0 0 10px 0;padding:0;min-height: auto;height:auto;" v-html="slider.title"></div>

        <div v-if="slider.slider_view_all.show" style="float:right;margin-bottom:15px;">
          <a :href="slider.slider_view_all.link">{{slider.slider_view_all.text}}</a>
        </div>
        <swiper
            v-if="slider.type === 'horizontal'"
            ref="mySwiper"
            :options="swiperOptions"
            @click-slide="(ind) => handleClickSlide(ind,grInd)"
        >
          <swiper-slide v-for="(slide,i) in JSON.parse(JSON.stringify(slider.slides)).filter(x => x.img)"
                        :key="i"
                        style="background:#fff;display:flex;padding:10px 0 10px 10px;margin-right:10px;" :style="`border-radius:${slider.radius}px;background:${slider.bg_group};cursor:${slide.useLink?'pointer':'default'};border:1px solid ${slider.border_color};height:${slider.horizontal.height}px;width: ${slider.horizontal.width}px;`">

            <div :class='`sliderHorizontal_${grInd}_${checkId(grInd,slide)}`' v-if="slide.img" style="overflow:hidden;height:100%;" :style="`border-top-left-radius:${slider.radius}px;border-bottom-left-radius:${slider.radius}px;max-width:${slider.horizontal.img_width}px;width:${slider.horizontal.img_width}px;`">
              <img :src="slide.img" :alt="slide.img" style="height:100%;object-fit: fill;" :style="`max-width:${slider.horizontal.img_width}px;width:${slider.horizontal.img_width}px`">
            </div>

            <div style="border-radius:0;display: flex;flex-direction: column;font-size:14px;justify-content: space-evenly;" :style="`width:calc(100% - ${slider.horizontal.img_width}px)`">
              <p  style="padding:5px;border-radius:0;min-height:auto;height:auto;width:100%;margin:0;overflow-wrap: break-word;" v-html="slide.text"></p>
            </div>
          </swiper-slide>
        </swiper>
        <swiper
            v-if="slider.type === 'vertical'"
            ref="mySwiper"
            :options="swiperOptions"
            @click-slide="(ind) => handleClickSlide(ind,grInd)"
        >
          <swiper-slide v-for="(slide,i) in JSON.parse(JSON.stringify(slider.slides)).filter(x => x.img)"
                        :key="i"
                        style="display:flex;background:#fff;flex-direction:column;" :style="`border-radius:${slider.radius}px;background:${slider.bg_group};cursor:${slide.useLink?'pointer':'default'};width:${slider.vertical.width}px;border:1px solid ${slider.border_color};height:${slider.vertical.height}px;`">


            <div  :class='`sliderVertical_${grInd}_${checkId(grInd,slide)}`' style="width:100%;" :style="`border-radius:${landing.menu.radius}px;`">
              <img :src="slide.img" :alt="slide.img" style="width:100%;object-fit: cover;" :style="`height:${slider.vertical.img_height}px;border-top-left-radius:${slider.radius}px;border-top-right-radius:${slider.radius}px;`">
            </div>
            <div style="border-radius:0;display: flex;flex-direction: column;font-size:14px;justify-content: space-evenly;">
              <p  style="padding:5px;border-radius:0;min-height:auto;height:auto;margin:0;overflow-wrap: break-word;
                      display:flex;justify-content:center;" v-html="slide.text"></p>
              <!--                  <a :href="slide.link" style="inline-size: 160px;overflow-wrap: break-word;color:#7f7f7f;">-->
              <!--                  </a>-->
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import 'swiper/swiper-bundle.css'
import "swiper/modules/pagination/pagination.min.css"
import SwiperCore, {
  Pagination
} from 'swiper';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
SwiperCore.use([Pagination]);
export default {
    props:['slides','position'],
    name:'SliderPreview',
    data(){
        return{
            sliderMenu:[],
            swiperOptions:{
                slidesPerView: 'auto',
                spaceBetween: 15,
              },
        }
    },
    components:{
        Swiper,
        SwiperSlide
    },
    computed:{
        ...mapState(['landing']),
    },
    methods:{
      checkId(gr,slide){
        return this.slides[gr].slides.indexOf(this.slides[gr].slides.find(x => x.img == slide.img));
      },
      handleClickSlide(ind,gr){
        if(this.landing.branding.slider[this.landing.current_lang][gr].slides[ind].useLink){
          window.open(this.landing.branding.slider[this.landing.current_lang][gr].slides[ind].link,'_blank');
        }
      },
  },

}
</script>

<style>

</style>
